<template>
  <div class="w-full">

    <div class="flex flex-col text-primary divide-y divide-gray">

      <div class="lb-navigation">

        <div class="col-span-2 flex items-center lg:hidden">
          <UIMobileMenuButton />

          <UIMobileSearchButton />
        </div>

        <Logo class="order-2 lb-nav-logo col-span-2"/>

        <LayoutNavigationMenu class="order-3" v-if="isShowMenu" />

        <!-- Arama Formu -->
        <SearchInput class="order-4 hidden lg:flex" is-expandabled="true"/>

        <!-- Üst Sağ - Kullanıcı Menu Alanı-->
        <LayoutNavigationUserContentArea />
      </div>

      <SearchInput class="w-full flex lg:hidden" v-if="isShowMobileSearch" is-expandabled="true"/>
    </div>
  </div>
</template>

<script setup>
const {isShowMenu, isShowMobileSearch} = useHelper()
</script>